import React, { useContext, useEffect, useState } from "react";
import Chart from "../../components/dashboard/Chart";
import SidebarComponent from "../../components/dashboard/Sidebar";
import Loader from "../../components/Loader";
import { AuthContext } from "../../context/authContext";
import axios from "axios";
import { BASE_URL } from "../../components/BaseUrl";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";

const Pools = () => {
  const { admin } = useContext(AuthContext);
  const [pools, setPools] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/txns/pools`)
      .then((res) => {
        console.log(res);
        setPools(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function deleteStrategy(strategyId) {
    axios
      .delete(`${BASE_URL}/txns/pool/${strategyId}`)
      .then((res) => {
        alert("Pool deleted successfully");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function updateMetrics() {
    setIsLoading(true);
    axios
      .post(`${BASE_URL}/admin/pool/update`)
      .then((res) => {
        alert("Metrics updated successfully");
        window.location.reload();
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }
  return (
    <div>
      <div>
        <Loader />
      </div>
      <div>
        <SidebarComponent user={admin} />
      </div>
      <div className="p-4 sm:ml-64 bg-[#0a0c0f] min-h-screen">
        <div className="p-4 border border-gray-600 min-h-screen border-dashed rounded-lg dark:border-gray-700 mt-20">
          <div className="flex items-center justify-between">
            <h2>All pools</h2>
            <button
              onClick={updateMetrics}
              className="w-full bg-[#b2ffce] rounded-md px-4 py-1 outline-none"
            >
              {isLoading ? (
                <CircularProgress color="inherit" size="25px" />
              ) : (
                "Update metrics"
              )}
            </button>
          </div>
          <div className="md:grid space-y-5 md:space-y-0 grid-cols-3 gap-4 mb-4 fadeIn">
            {pools?.map((pool) => {
              return (
                <div className="rounded bg-[#181b20] p-5">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-3">
                      <div>
                        <img
                          alt="manager"
                          className="w-14 h-14 rounded-full"
                          src={pool.image}
                        />
                      </div>
                      <div>
                        <h1 className="text-2xl font-medium text-gray-300">
                          {pool.name}
                        </h1>
                        <p className=" text-gray-400">{pool.manager}</p>
                      </div>
                    </div>
                    <div className="space-y-2">
                      <Link to={`/strategy/${pool._id}`}>
                        <button className="w-full bg-[#b2ffce] rounded-md px-4 py-1 outline-none">
                          View
                        </button>
                      </Link>
                      <div onClick={() => deleteStrategy(pool._id)}>
                        <button className="w-full bg-red-600 text-white rounded-md px-4 py-1 outline-none">
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-400 w-full h-[0.5px] mt-2"></div>
                  <div className="flex items-center justify-between mt-3">
                    <div>
                      <p className="text-sm text-gray-400">
                        Value Managed: <strong>${pool.valueManaged}</strong>
                      </p>
                      <p className="text-sm text-gray-400">
                        Risk Factor: {}
                        <span
                          className={`${
                            pool.riskFactor.split("/")[0] > 5
                              ? "text-red-500"
                              : "text-[#b2ffce]"
                          } `}
                        >
                          {pool.riskFactor}
                        </span>
                      </p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-400">
                        3M Return:{" "}
                        <span className="text-[#b2ffce]">
                          +{pool.threeMonthsReturn}%
                        </span>
                      </p>
                      <p className="text-sm text-gray-400">
                        6M Return:{" "}
                        <strong className="text-[#b2ffce]">
                          +{pool.sixMonthsReturn}%
                        </strong>
                      </p>
                    </div>
                  </div>
                  <div className="mt-4">
                    <Chart
                      yearly={pool.yearlyReturn}
                      monthly={pool.oneMonthReturn}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pools;
